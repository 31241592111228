<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$toPath('/web/classListCtrY')"
            >班级列表</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ projectName }}-班级详情</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs
          v-model="activeName"
          @tab-click="tabsChange"
          v-if="
            $route.query.hasRollCall == 'true' &&
            $route.query.hasGovRollCall == 'true' &&
            $route.query.projectSource != '60'
          "
        >
          <el-tab-pane label="基本信息" name="first">
            <div class="framePage-scroll">
              <div class="ovy-a">
                <h3 style="padding-bottom: 20px">基本信息</h3>

                <el-form
                  ref="Form"
                  :model="Form"
                  :rules="rules"
                  label-width="130px"
                  class
                >
                  <el-form-item
                    label="班级名称："
                    prop="projectName"
                    class="form-item"
                  >
                    <el-input
                      v-model="Form.projectName"
                      placeholder="请输入班级名称"
                      size="small"
                    />
                  </el-form-item>
                  <el-form-item
                    label="行政区划："
                    prop="areaId"
                    class="form-item"
                  >
                    <el-cascader
                      v-model="Form.areaId"
                      :options="areatreeList"
                      :props="propsarea"
                      :disabled="disabled"
                      size="small"
                    ></el-cascader>
                  </el-form-item>
                  <!-- 只有上海才显示街道 - 这三个区必填，其余非必填 -->
                  <el-form-item label="所属街道：" :prop="['310114', '310112','310118'].includes(Form.areaId)?'street':''" class="form-item" v-if="zF.getString2(Form.areaId,2) == '31'">
                    <el-select
                      size="small"
                      v-model="Form.street"
                      remote
                      type="text"
                      :remote-method="getStreetData"
                      filterable
                      clearable
                      placeholder="请选择街道"
                    >
                      <el-option
                        v-for="item in streetData"
                        :key="item.streetName"
                        :label="item.streetName"
                        :value="item.streetName"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="有效日期："
                    prop="Effectivedate"
                    class="form-item"
                  >
                    <el-date-picker
                      size="small"
                      v-model="Form.Effectivedate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="timestamp"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item
                    label="报名截止时间："
                    prop="deadline"
                    class="form-item"
                  >
                    <el-date-picker
                      size="small"
                      v-model="Form.deadline"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="timestamp"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item
                    label="负责人："
                    prop="projectUser"
                    class="form-item"
                  >
                    <el-input
                      v-model="Form.projectUser"
                      placeholder="请输入负责人"
                      size="small"
                    />
                  </el-form-item>
                  <el-form-item
                    label="负责人电话："
                    prop="projectUserPhone"
                    class="form-item"
                  >
                    <el-input
                      v-model="Form.projectUserPhone"
                      placeholder="请输入负责人电话"
                      maxlength="12"
                      size="small"
                    />
                  </el-form-item>
                  <el-form-item
                    label="培训类型："
                    prop="Trainingtype"
                    class="form-item"
                  >
                    <tree
                      ref="tree"
                      @eventBtn="childBack"
                      :ruleForm="Form"
                      size="small"
                      @clearParams="clearParams"
                      modal
                      type
                      :typeStu="false"
                      :threePostsLimit="'1'"
                    />
                  </el-form-item>
                  <el-form-item
                    label="培训人数"
                    prop="projectPeople"
                    class="form-item"
                    v-if="selectType != '0'"
                    required
                  >
                    <el-input-number
                      v-model="Form.projectPeople"
                      @change="handleChange"
                      :min="1"
                      style="width: 100%"
                      size="small"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="培训地点"
                    prop="projectAddress"
                    class="form-item"
                    v-if="selectType != '0'"
                  >
                    <el-input
                      type="text"
                      v-model="Form.projectAddress"
                      size="small"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="期次"
                    prop="projectPc"
                    class="form-item"
                    v-if="selectType != '0'"
                  >
                    <el-input
                      type="text"
                      v-model="Form.projectPc"
                      size="small"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="考试机构"
                    prop="projectPaperComp"
                    class="form-item"
                    v-if="selectType != '0'"
                  >
                    <el-input
                      type="text"
                      v-model="Form.projectPaperComp"
                      size="small"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="考试时间"
                    prop="projectPaperDate"
                    class="form-item"
                    v-if="selectType != '0'"
                  >
                    <el-date-picker
                      v-model="Form.projectPaperDate"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="timestamp"
                      placeholder="选择日期"
                      size="small"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item
                    label="人群类型"
                    prop="projectCrowd"
                    class="form-item"
                    v-if="
                      selectType == '4' ||
                      selectType == '5' ||
                      selectType == '15' ||
                      selectType == '16'
                    "
                  >
                    <el-select
                      v-model="Form.projectCrowd"
                      placeholder="请选择"
                      size="small"
                      :disabled="disabled"
                    >
                      <el-option
                        v-for="item in CrowdtypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="级别"
                    class="form-item"
                    v-show="
                      params.trainFormId == '5' || params.trainFormId == '16'
                    "
                  >
                    <el-select
                      v-model="Form.subsidyLevel"
                      placeholder="请选择"
                      size="small"
                      clearable
                      :disabled="
                        Form.auditState == '10' || Form.auditState == '20'
                      "
                    >
                      <el-option
                        v-for="item in levelList"
                        :key="item.subsidyLevel"
                        :label="item.subsidyLevel"
                        :value="item.subsidyLevel"
                      ></el-option>
                    </el-select>
                    <p>没有您想要的级别,请去补贴标准里新增。</p>
                  </el-form-item>
                  <el-form-item
                    label="业务员"
                    prop="salesmanId"
                    class="form-item"
                  >
                    <el-select
                      v-model="Form.salesmanId"
                      placeholder="请选择"
                      size="small"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in SalesmanList"
                        :key="item.adminId"
                        :label="item.fullname"
                        :value="item.adminId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="申请表图片"
                    prop="thumbnail"
                    class="form-item"
                    v-if="selectType != '0'"
                  >
                    <!-- <el-upload
                      :on-change="handleAvatarSuccess2"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="Form.thumbnail || require('@/assets/develop.png')"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                    <p style="color: #f46173">
                      请上传640*375（长*宽）像素的PNG、JPG格式的图片，且文件大小不超过1MB！
                    </p> -->
                    <el-upload
                      class="upload-btndrag"
                      :action="actionUrl"
                      :on-error="handleError"
                      :on-success="handleSuccess"
                      :on-change="uploadChange1"
                      :show-file-list="false"
                      :auto-upload="false"
                      drag
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        将文件拖到此处，或<em>点击上传</em>
                      </div>
                    </el-upload>
                    <div
                      v-if="Form.uploadfileList.length"
                      style="margin-top: 10px; margin-left: 10px"
                    >
                      当前选择文件：
                      <div style="display: flex; flex-direction: column">
                        <span
                          style="color: #409eff"
                          v-for="(item, index) in Form.uploadfileList"
                          :key="index"
                        >
                          {{ item.fileName }}
                          <a
                            @click="lookExl(item)"
                            style="color: #409eff; margin: 0 15px"
                            >预览</a
                          >
                          <a
                            @click="reomveExlUpload2(index)"
                            style="color: red; margin: 0 15px"
                            >删除</a
                          >
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <p style="margin-top: 10px; margin-left: 10px">
                        未选择文件
                      </p>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="项目编号："
                    prop="projectRecordno"
                    class="form-item"
                    :rules="[
                      {
                        required: false,
                        message: '请输入项目编号',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="Form.projectRecordno"
                      placeholder="请输入项目编号"
                      :disabled="disabled"
                      size="small"
                      maxlength="50"
                    />
                  </el-form-item>
                  <el-form-item
                    label="备案课时数："
                    prop="recordClasshours"
                    class="form-item"
                    :rules="[
                      {
                        required:
                          ['3101', '310000'].includes(Form.areaId) ||
                          (['130', '131', '139'].includes(Form.areaId) &&
                            disableStu)
                            ? true
                            : false,
                        message: '请输入备案课时数',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="Form.recordClasshours"
                      placeholder="请输入备案课时数"
                      maxlength="12"
                      @keyup.native="
                        Form.recordClasshours = zF.oninput2(
                          Form.recordClasshours,
                          2
                        )
                      "
                      size="small"
                    />
                  </el-form-item>
                </el-form>
                <div class="bottom-btn">
                  <el-button @click="cancle" class="bgc-bv">取消</el-button>
                  <el-button @click="seveData('ruleForm')" class="bgc-bv"
                    >保存</el-button
                  >
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="机构点名"
            name="second"
            v-if="$route.query.hasRollCall == 'true'"
          >
            <organizationRollCall :rollType="rollType" />
          </el-tab-pane>
          <el-tab-pane
            label="监管点名"
            name="three"
            v-if="$route.query.hasGovRollCall == 'true'"
          >
            <organizationRollCall :rollType="rollType" />
          </el-tab-pane>
        </el-tabs>
        <el-tabs
          v-model="activeName"
          @tab-click="tabsChange"
          v-else-if="
            $route.query.hasRollCall == 'false' &&
            $route.query.hasGovRollCall == 'true' &&
            this.projectSource == '60'
          "
        >
          <el-tab-pane label="基本信息" name="first">
            <guangzEditClass />
          </el-tab-pane>
          <el-tab-pane label="参数信息" name="second">
            <guangzQuery />
          </el-tab-pane>
        </el-tabs>
        <div
          class="framePage-scroll"
          v-else-if="
            $route.query.hasRollCall == 'false' &&
            $route.query.hasGovRollCall == 'true' &&
            this.projectSource != '60'
          "
        >
          <div class="ovy-a">
            <h3 style="padding-bottom: 20px">基本信息</h3>
            <el-form
              ref="Form"
              :model="Form"
              :rules="rules"
              :disabled="projectSource == '90' && Form.auditState == '30'"
              label-width="130px"
              class
            >
              <el-form-item
                label="班级名称："
                prop="projectName"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectName"
                  placeholder="请输入班级名称"
                  size="small"
                />
              </el-form-item>
              <el-form-item label="行政区划：" prop="areaId" class="form-item">
                <el-cascader
                  v-model="Form.areaId"
                  :options="areatreeList"
                  :props="propsarea"
                  :disabled="disabled"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <!-- 只有上海才显示街道 - 这三个区必填，其余非必填 -->
              <el-form-item label="所属街道：" :prop="['310114', '310112','310118'].includes(Form.areaId)?'street':''" class="form-item" v-if="zF.getString2(Form.areaId,2) == '31'">
                <el-select
                  size="small"
                  v-model="Form.street"
                  remote
                  type="text"
                  :remote-method="getStreetData"
                  filterable
                  clearable
                  placeholder="请选择街道"
                >
                  <el-option
                    v-for="item in streetData"
                    :key="item.streetName"
                    :label="item.streetName"
                    :value="item.streetName"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="有效日期："
                prop="Effectivedate"
                class="form-item"
              >
                <el-date-picker
                  size="small"
                  v-model="Form.Effectivedate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="报名截止时间："
                prop="deadline"
                class="form-item"
              >
                <el-date-picker
                  size="small"
                  v-model="Form.deadline"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="负责人："
                prop="projectUser"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectUser"
                  placeholder="请输入负责人"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="负责人电话："
                prop="projectUserPhone"
                class="form-item"
              >
                <el-input
                  v-model="Form.projectUserPhone"
                  placeholder="请输入负责人电话"
                  maxlength="12"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                  label="培训类型："
                  prop="Trainingtype"
                  v-if="projectSource!='100'"
                  class="form-item"
                >
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="Form"
                    size="small"
                    @clearParams="clearParams"
                    modal
                    type
                    :typeStu="false"
                    :threePostsLimit='1'
                  />
                </el-form-item>
                <el-form-item
                  label="培训类型："
                  prop="Trainingtype"
                  v-else
                  class="form-item"
                >
                  <el-cascader
                    v-model="Form.trainFormId"
                    :options="TrainTypeList"
                    style="width: 100%;"
                    disabled
                    :props="{
                      emitPath: false,
                      value: 'id',
                      label: 'label',
                      children: 'children',
                      checkStrictly: true,
                    }"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="培训人数"
                prop="projectPeople"
                class="form-item"
                v-if="selectType != '0'"
                required
              >
                <el-input-number
                  v-model="Form.projectPeople"
                  @change="handleChange"
                  :min="1"
                  style="width: 100%"
                  size="small"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="培训地点"
                prop="projectAddress"
                class="form-item"
                v-if="selectType != '0'"
              >
                <el-input
                  type="text"
                  v-model="Form.projectAddress"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="期次"
                prop="projectPc"
                class="form-item"
                v-if="selectType != '0'"
              >
                <el-input
                  type="text"
                  v-model="Form.projectPc"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="考试机构"
                prop="projectPaperComp"
                class="form-item"
                v-if="selectType != '0'"
              >
                <el-input
                  type="text"
                  v-model="Form.projectPaperComp"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="考试时间"
                prop="projectPaperDate"
                class="form-item"
                v-if="selectType != '0'"
              >
                <el-date-picker
                  v-model="Form.projectPaperDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="timestamp"
                  placeholder="选择日期"
                  size="small"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="人群类型"
                prop="projectCrowd"
                class="form-item"
                v-if="selectType == '5'"
              >
                <el-select
                  v-model="Form.projectCrowd"
                  placeholder="请选择"
                  size="small"
                  :disabled="disabled"
                >
                  <el-option
                    v-for="item in CrowdtypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="级别"
                class="form-item"
                v-show="params.trainFormId == '5' || params.trainFormId == '16'"
              >
                <el-select
                  v-model="Form.subsidyLevel"
                  placeholder="请选择"
                  size="small"
                  clearable
                  :disabled="Form.auditState == '10' || Form.auditState == '20'"
                >
                  <el-option
                    v-for="item in levelList"
                    :key="item.subsidyLevel"
                    :label="item.subsidyLevel"
                    :value="item.subsidyLevel"
                  ></el-option>
                </el-select>
                <p>没有您想要的级别,请去补贴标准里新增。</p>
              </el-form-item>
              <el-form-item label="业务员" prop="salesmanId" class="form-item">
                <el-select
                  v-model="Form.salesmanId"
                  placeholder="请选择"
                  size="small"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in SalesmanList"
                    :key="item.adminId"
                    :label="item.fullname"
                    :value="item.adminId"
                  ></el-option>
                </el-select>
              </el-form-item>
               <el-form-item label="是否班级补贴" prop="isSubsidy" class="form-item">
                <el-select
                  v-model="Form.isSubsidy"
                  placeholder="请选择"
                  size="small"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in SubsidyList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="项目编号："
                prop="projectRecordno"
                class="form-item"
                :rules="[
                  {
                    required: zF.getString2(Form.areaId,2) == '31'&&params.trainFormId == '5' ? true: false,
                    message: '请输入项目编号',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="Form.projectRecordno"
                  placeholder="请输入项目编号"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                label="备案课时数："
                prop="recordClasshours"
                class="form-item"
                :rules="[
                  {
                  required: ['3101', '3100'].includes(Form.areaId.slice(0,4)) ||
                      (['130', '131', '139'].includes(Form.areaId.slice(0,3)) &&
                        disableStu) ? true : false,
                    message: '请输入备案课时数',
                    trigger: 'change',
                  },
                ]"
              >
                <el-input-number
                  v-model="Form.recordClasshours"
                  :min="0"
                  :max="999"
                  style="width: 100%"
                  size="small"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="培训方式"
                prop="trainMethod"
                class="form-item"
                v-if="Form.areaId.indexOf('410') != -1"
              >
                <el-select
                  v-model="Form.trainMethod"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in TrainingMethodsList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="申请表图片"
                prop="thumbnail"
                class="form-item"
                v-if="selectType != '0'"
              >
                <el-upload
                  class="upload-btndrag"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange1"
                  :show-file-list="false"
                  :auto-upload="false"
                  drag
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                </el-upload>
                <div
                  v-if="Form.uploadfileList.length > 0"
                  style="margin-top: 10px; margin-left: 10px"
                >
                  当前选择文件：
                  <div style="display: flex; flex-direction: column">
                    <span
                      style="color: #409eff"
                      v-for="(item, index) in Form.uploadfileList"
                      :key="index"
                    >
                      {{ item.fileName }}
                      <a
                        @click="lookExl(item)"
                        style="color: #409eff; margin: 0 15px"
                        >预览</a
                      >
                      <a
                        @click="reomveExlUpload2(index)"
                        style="color: red; margin: 0 15px"
                        >删除</a
                      >
                    </span>
                  </div>
                </div>
                <div >
                  <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
                </div>
              </el-form-item>
            </el-form>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData('ruleForm')" class="bgc-bv"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import organizationRollCall from "@/views/classListCom/classDetailsFile/organizationRollCall.vue";
import guangzQuery from "@/views/classListCom/classDetailsFile/guangzQuery.vue";
import guangzEditClass from "@/views/classListCom/classDetailsFile/guangzEditClass.vue";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "classDetailY",
  components: {
    tree,
    organizationRollCall,
    guangzQuery,
    guangzEditClass,
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的负责人电话"));
      } else {
        callback();
      }
    };

    return {
      activeName: "first",
      paperId: false,
      disabled: true,
      editJson: {},
      areatreeList: [],
      datatreeList: [], //培训类型
      CrowdtypeList: [],
      props: {
        value: "id",
        label: "label",
      },
      streetData:[],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      selectType: "0",
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        street:"",
        Effectivedate: [],
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        projectAddress: "",
        projectPc: "",
        projectPaperComp: "",
        Examinationtime: "",
        proType: "",
        thumbnail: "",
        fileKey: "",
        Trainingtype: "",
        projectClassHourCertificate: true,
        auditState: "",
        auditReason: "",
        subsidyLevel: "", // 级别
        salesmanId: "",
        projectRecordno: "",
        recordClasshours: "",
        isSubsidy:'',
      },
      // ruleForm: {
      //   Trainingtype:''
      // },
      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        trainMethod: [
          { required: true, message: "请选择培训方式", trigger: "change" },
        ],
      },
      levelList: [],
      SalesmanList: [],
      TrainingMethodsList: [],
      Form2: {},
      previewLoding1: false,
      trainTypeIds: [], //职业技能id集合
      disableStu: false, // 判断是否是职业技能下
      TrainTypeList: [],//企业培训类型list
      SubsidyList:[
        {
          value:true,
          label:'是'
        },
        {
          value:false,
          label:'否'
        }
      ]
    };
  },
  watch: {
    "params.trainFormId": {
      handler: function (val) {
        if (val != "5") {
          this.Form.subsidyLevel = "";
        }
      },
      deep: true,
    },
  },
  created() {
    this.projectName = sessionStorage.getItem("projectName");
    this.projectId = sessionStorage.getItem("projectId");
    this.projectSource = sessionStorage.getItem("projectSource");
    this.compId = sessionStorage.getItem("compId");
    this.getCrowdtypeList();
    this.getareatree();
    this.getlevel();
    this.getSalesmanList();
    this.getDetail();
    this.getTrainTypeList2();
    this.getTrainTypeArrData()
  },
  methods: {
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    tabsChange(e) {
      this.activeName = e.name;
      if (e.name == "second") {
        this.rollType = "10";
      }
      if (e.name == "three") {
        this.rollType = "20";
      }
    },
    childBack(params) {
      this.params = { ...params };
      console.log(this.params);
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const ProjectTrainMethod = this.$setDictionary(
        "PROJECT_TRAIN_METHOD",
        "list"
      );
      const list = [],
        ProjectTrainMethodList = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      for (const key in ProjectTrainMethod) {
        ProjectTrainMethodList.push({
          value: key,
          label: ProjectTrainMethod[key],
        });
      }
      this.CrowdtypeList = list;
      this.TrainingMethodsList = ProjectTrainMethodList;
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.thumbnail = result.data.fileURL;
            this.Form.fileKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getlevel() {
      this.$post("/biz/new/subsidy/standard/projectSubsidyList", {
        projectId: this.projectId,
      }).then((ret) => {
        this.levelList = ret.data;
      });
    },
    getSalesmanList() {
      this.$post("/biz/project/querySalesmanList", {}).then((ret) => {
        this.SalesmanList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    // 街道
    getStreetData(val){
     let cData = {
        areaId: this.Form.areaId
      }
      if(val){
        cData.streetName = val;
      }
      this.$post("/sys/area/street", cData)
        .then((res) => {
          this.streetData = res.data;
        })
        .catch((err) => {
          console.log(err)
        });
    },
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName,
        projectSource: this.projectSource,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        signEndDate: this.Form.deadline,
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        projectClassHourCertificate:
          this.Form.projectClassHourCertificate == true ? true : false,
        subsidyLevel: this.Form.subsidyLevel,
        salesmanId: this.Form.salesmanId,
        trainMethod: this.Form.trainMethod,
        projectRecordno: this.Form.projectRecordno,
        recordClasshours: this.Form.recordClasshours,
        isSubsidy: this.Form.isSubsidy,
      };
      if(this.Form.street){
        parmar.street = this.Form.street;
      }
      if (this.Form.projectAddress) {
        parmar.projectAddress = this.Form.projectAddress;
      }
      if (this.Form.projectCrowd) {
        parmar.projectCrowd = this.Form.projectCrowd;
      }
      if (this.Form.projectId) {
        parmar.projectId = this.Form.projectId;
      }
      if (this.Form.projectPc) {
        parmar.projectPc = this.Form.projectPc;
      }
      if (this.Form.projectPaperComp) {
        parmar.projectPaperComp = this.Form.projectPaperComp;
      }
      if (this.Form.projectPaperDate) {
        parmar.projectPaperDate = this.Form.projectPaperDate;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.Form.uploadfileList.length > 0) {
        parmar.applicationForm = this.Form.uploadfileList;
      }
      this.$post("/run/project/modify", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              path: "/web/classListCtrY",
              query: {
                refresh: true,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    getDetail() {
      this.$post("/biz/project/query", { projectId: this.projectId }).then(
        (ret) => {
          let data = ret.data;
          let res = JSON.stringify(data);
          res = res.replace(/areaId/g, "value");
          res = res.replace(/areaName/g, "label");
          res = res.replace(/trainTypeId/g, "id");
          res = JSON.parse(res);
          this.id = res.id;
          this.selectType = res.id;
          this.Form = {
            projectId: res.projectId,
            projectName: res.projectName,
            Effectivedate: [res.startDateLong, res.endDateLong],
            areaId: res.value,
            street: res.street || "",
            deadline: res.signEndDateLong,
            projectUser: res.projectUser,
            projectUserPhone: res.projectUserPhone,
            projectPaperDate: res.projectPaperDateLong,
            projectPaperComp: res.projectPaperComp,
            projectPc: res.projectPc,
            projectAddress: res.projectAddress,
            projectPeople: res.projectPeople,
            thumbnail: res.applicationFormUrl,
            projectCrowd: res.projectCrowd,
            fileKey: res.applicationForm,
            projectClassHourCertificate: res.projectClassHourCertificate,
            auditState: res.auditState,
            auditReason: res.auditReason,
            trainFormId: res.id,
            trainTypeNamePath: res.trainTypeNamePath,
            postName: res.codeBo.postName,
            industryNamePath: res.codeBo.industryNamePath,
            occupationNamePath: res.codeBo.occupationNamePath,
            trainLevelName: res.codeBo.trainLevelName,
            subsidyLevel: res.subsidyLevel || "",
            salesmanId: res.salesmanId,
            trainMethod: res.trainMethod,
            uploadfileList: res.list || [],
            projectRecordno: res.projectRecordno,
            recordClasshours: res.recordClasshours,
            isSubsidy: res.isSubsidy,
          };
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (res.codeBo.trainTypeNamePath) {
            name1 = res.codeBo.trainTypeNamePath;
            this.params.trainFormId = res.codeBo.id;
          }
          if (res.codeBo.postName) {
            name2 = "/" + res.codeBo.postName;
            this.params.postFormId = res.codeBo.postId;
          }
          if (res.codeBo.industryNamePath) {
            name3 = "/" + res.codeBo.industryNamePath;
            this.params.industryFormId = res.codeBo.industryId;
          }
          if (res.codeBo.occupationNamePath) {
            name4 = "/" + res.codeBo.occupationNamePath;
            this.params.occFormId = res.codeBo.occupationId;
          }
          if (res.codeBo.trainLevelName) {
            name5 = "/" + res.codeBo.trainLevelName;
            this.params.levelFormId = res.codeBo.trainLevelId;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
          if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
            this.disableStu = true;
          } else {
            this.disableStu = false;
          }
          if (this.zF.getString2(res.value,2) == '31'){
            this.getStreetData();
          }
        }
      );
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.uploadfileList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    reomveExlUpload2(index) {
      this.Form.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    getTrainTypeList2() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          // this.trainTypeList = res.data;
          res.data.map((el) => {
            if (el.id == "4") {
              this.trainTypeIds.push(el.id);
              el.children.map((els) => {
                this.trainTypeIds.push(els.id);
                if (els.children) {
                  els.children.map((els1) => {
                    this.trainTypeIds.push(els1.id);
                  });
                }
              });
            }
          });
          if (this.trainTypeIds.indexOf(this.Form.trainTypeId) != -1) {
            this.disableStu = true;
          } else {
            this.disableStu = false;
          }
          console.log(this.disableStu);
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
#pane-first {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
</style>
<style lang="less">
.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
