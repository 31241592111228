<template>
    <div class="framePage-scroll">
      <div class="ovy-a">
          <h3 style="padding-bottom: 20px">参数信息</h3>
                <el-form
                  ref="Form"
                  :model="Form"
                  :rules="rules"
                  label-width="150px"
                  class
                >
                  <el-form-item
                    label="机构名称："
                    prop="orgName"
                    class="form-item"
                  >
                    <p>{{Form.orgName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="机构编码："
                    prop="orgCode"
                    class="form-item"
                  >
                    <p>{{Form.orgCode}}</p>
                  </el-form-item>
                  <el-form-item
                    label="备案号："
                    prop="filingCode"
                    class="form-item"
                  >
                   <p>{{Form.filingCode}}</p>
                  </el-form-item>
                  <el-form-item
                    label="是否实名认证："
                    prop="deadline"
                    class="form-item"
                  >
                 
                     <p>{{Form.realNameAuthentication == '0'? '不需要':'需要'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="每日时长限制："
                    prop="studyHourLimit"
                    class="form-item"
                  >
                    <p>{{Form.studyHourLimit == '0'? '无限制':Form.studyHourLimit}}</p>
                  </el-form-item>
                  <el-form-item
                    label="防挂机："
                    prop="videoOnHook"
                    class="form-item"
                  >
                    <p>{{Form.videoOnHook == '0'? '不需要':Form.videoOnHook == '1' ? '弹验证码':'人脸识别'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="认证形式："
                    prop="triggerType"
                    class="form-item"
                  >
                     <p>{{Form.triggerType == '0'? '固定次数/'+ Form.triggerValue+'次':Form.videoOnHook == '1' ? '固定时间/' + Form.triggerValue+'分钟':'每次递增/'+ Form.triggerValue+'分钟'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="首次观看人脸识别："
                    prop="videoStartFace"
                    class="form-item"
                  >
                    <p>{{Form.videoStartFace == '0'?'不需要':'需要'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="录制视频："
                    prop="videoPlayRecord"
                    class="form-item"
                  >
                   <p>{{Form.videoPlayRecord == '0'?'不需要':'需要(在视频' + $setDictionary("RECORD_VID_STATE", Form.recordVideoState) +'处,录制' + Form.recordVideoTime + '秒)'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="是否按顺序学习："
                    prop="orderStudy"
                    class="form-item"
                  >
                   <p>{{Form.orderStudy == '0'?'否':'是'}}</p>
                  </el-form-item>
                </el-form>
      </div>
    </div>
</template>
<script>

import List from "@/mixins/List";
export default {
  name: "myresourseList",
  components: {
  },
  mixins: [List],
  data() {
    return {
      activeName: "second",
      Form: {
      },
    };
  },
  computed: {},
  created() {
      this.projectId = sessionStorage.getItem("projectId");
      this.getDetail()
  },
  methods: {
      getDetail() {
      this.$post("/biz/canton/selectCantonProjectDetail", { projectId: this.projectId }).then(
        (ret) => {
          let data = ret.data;
          this.Form = {
           ...data
          };
        }
      );
    },
  }
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }
  .el-dialog__title {
    color: #333;
  }
  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
