<template>
    <div class="framePage-scroll">
              <div class="ovy-a">
                <h3 style="padding-bottom: 20px">基本信息</h3>
                <el-form
                  ref="Form2"
                  :model="Form2"
                  :rules="rules"
                  label-width="130px"
                  class
                >
                  <el-form-item
                    label="班级名称："
                    prop="projectName"
                    class="form-item"
                  >
                    <p>{{Form2.projectName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="行政区划："
                    prop="areaName"
                    class="form-item"
                  >
                      <p>{{Form2.areaName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="有效日期："
                    prop="Effectivedate"
                    class="form-item"
                  >
                  <p>{{Form2.startDate}}~{{Form2.endDate}}</p>
                    
                  </el-form-item>
                  <el-form-item
                    label="报名截止时间："
                    prop="signEndDate"
                    class="form-item"
                  >
                    <p>{{Form2.signEndDate}}</p>
                  </el-form-item>
                  <el-form-item
                    label="负责人："
                    prop="projectUser"
                    class="form-item"
                  >
                   <p>{{Form2.projectUser}}</p>
                  </el-form-item>
                  <el-form-item
                    label="负责人电话："
                    prop="projectUserPhone"
                    class="form-item"
                  >
                    <p>{{Form2.projectUserPhone}}</p>
                  </el-form-item>
                  <el-form-item
                    label="培训类型："
                    prop="Trainingtype"
                    class="form-item"
                  >
                    <p>{{Form2.categoryName}}</p>
                  </el-form-item>
                  <el-form-item
                    label="培训人数："
                    prop="projectPeople"
                    class="form-item"
                  >
                    {{Form2.projectPeople}}
                  </el-form-item>
                  <el-form-item
                    label="培训地点："
                    prop="projectAddress"
                    class="form-item"
                  >
                    <p>{{Form2.projectAddress}}</p>
                  </el-form-item>
                  <el-form-item
                    label="期次："
                    prop="projectPc"
                    class="form-item"
                  >
                   <p>{{Form2.projectPc}}</p>
                  </el-form-item>
                  <el-form-item
                    label="考试机构："
                    prop="projectPaperComp"
                    class="form-item"
                  >
                    <p>{{Form2.projectPaperComp}}</p>
                  </el-form-item>
                  <el-form-item
                    label="考试时间："
                    prop="projectPaperDate"
                    class="form-item"
                  >
                   <p>{{Form2.projectPaperDate}}</p>
                  </el-form-item>
                  <el-form-item
                    label="人群类型："
                    prop="projectCrowd"
                    class="form-item"
                  >
                   <p>{{Form2.projectCrowd == '10'?'重点人群':Form2.projectCrowd == '20'?'企业职工':'其他'}}</p>
                  </el-form-item>
                  <el-form-item
                    label="申请表图片："
                    prop="thumbnail"
                    class="form-item"
                  >
                    <el-upload
                      :on-change="handleAvatarSuccess2"
                      :before-upload="beforeAvatarUpload2"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      disabled
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="Form2.applicationForm || require('@/assets/develop.png')"
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </el-form-item>
                </el-form>
                <div class="bottom-btn">
                  <el-button @click="cancle" class="bgc-bv">取消</el-button>
                </div>
              </div>
            </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import { mapGetters } from "vuex";
export default {
  name: "TestPaper",
  components: {
  },
  mixins: [UploadPic, DatePicker],
  data() {
    return {
      Form2: {
      },
    };
  },
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.projectSource = sessionStorage.getItem("projectSource");
    this.getDetail2(this.projectId);
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    })
  },
  methods: {
   getDetail2() {
      this.$post("/biz/canton/selectCantonProjectDetail", { projectId: this.projectId }).then(
        (ret) => {
          let data = ret.data;
          this.Form2 = {
           ...data
          };
        }
      );
    },
    cancle() {
      this.$router.back();
    }
  }
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-item {
    width: 45%;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
</style>